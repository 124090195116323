<template>
    <div>
        <h1>Recognition</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import Options from "./recognition_opt";
    import Results from "./utils/Results";
    import Fields from "./utils/Fields";

    export default {
        name: "recognition",
        components: {
            Results,
            Options,
            Fields,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {val: '', qsField: '[datetimes][recognition_date_from]'},
                    endDate: {val: '', qsField: '[datetimes][recognition_date_to]'},
                    value: {val: '0', qsField: '[numbers][cr.value_id]'},
                    recognizedId: {val: '', qsField: '[numbers][cr.nominated_user_id]'},
                    cdiLocationId: {val: '0', qsField: '[numbers][cr.cdi_location_id]'}
                },
                groupByFields: {},
                dataFields: {
                    recognition_date: {label: 'Recognition date', checked: true},
                    value: {label: 'Value', checked: true},
                    nominating_user: {label: 'Nominating', checked: true},
                    nominated_user: {label: 'Nominated', checked: true},
                    nominated_user_location: {label: 'Location', checked: false},
                    customer_benefit: {label: 'Customer Benefit', checked: false}
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        }, created() {
            this.$appStore.setTitle(['Recognition']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields,this.dataFields);
        },
    }
</script>

<style scoped>

</style>